<template>
  <div class="home">
    <!-- <img alt="Vue logo" src="../assets/logo.png"> -->
    <HelloWorld msg="Welcome to Energy Paths LLC"/>
    <!-- <div class="mx-auto" style="width:80%">
      <div class="row, mx-auto text-secondary"><h2>Blending Neigong Balancing & Reiki Healing</h2></div>
      <div class="row, mx-auto">
         <a href="tel:12623704314" class="link-color">262-370-4314</a>
         </div>
         <div><a class="link-color" href="#appointments">Appointments</a></div>
         <div class="row"><br></div>
      <div class="row, mx-auto">
        <a class="link-color" href="https://goo.gl/maps/sQvsTGzKiE9ZB8kP6">
        12100 W Dearbourn Ave
        </a>
      </div>
      <div class="row, mx-auto">
        <a class="link-color" href="https://goo.gl/maps/sQvsTGzKiE9ZB8kP6">
        Wauwatosa, WI 53226</a></div>
      <div class="row"><br></div>
      
      <div class="row"><br></div>
    </div> -->
    <!-- <div class="row, mx-auto"><h3>About Me</h3></div> -->
    <div id="aboutme" class="mx-auto text-secondary" style="width:80%;">
    <h1>Blending Neigong & Reiki for Your Ultimate Healing</h1>
    <h2 class="link-color link-offset-2 link-offset-3-hover link-underline link-underline-opacity-0 link-underline-opacity-75-hover">Call or Text: 262-370-4314 </h2>
    <h2 class="link-color link-offset-2 link-offset-3-hover link-underline link-underline-opacity-0 link-underline-opacity-75-hover">energypathsllc@gmail.com</h2>
    <h6>Main Office co-located with Banyan Tree Healing</h6> 
<h6>12100 W Dearborn Ave
Wauwatosa, WI 53226</h6>


<h4>Find me at <a class="link-color" href="https://www.topazapothecary.com/classes-and-events">Topaz Apothecary</a> in Waukesha or other pop up events for special rates and unique experiences <a href="https://www.facebook.com/profile.php?id=100093296416895&sk=events"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-facebook" viewBox="0 0 16 16">
  <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951"/>
</svg></a></h4>
<h5 class="text-start fw-bold">Meet Dale</h5>
<p class="text-start fw-bold">Hello! I'm Dale. My expertise spans across Reiki, a Japanese technique for stress reduction and relaxation that also promotes healing, 
  and Neigong, an ancient Chinese practice focusing on cultivating internal energy through the life force energy of Qi.
My unique hybrid approach brings balance and harmonization to fortify your physical vitality, energy flow, mental clarity, and emotional well-being. 
You can think of me as an energy architect. Using the subtle, yet powerful techniques of Reiki to facilitate your body's natural healing process to 
activate your parasympathetic nervous system helping to restore your physical and emotional well-being. Meanwhile, incorporating the Chinese 
method of Neigong to dive deep into the realm of your internal energy, enhancing your body's capacity to harness and direct Qi, get you to stillness, 
and experience somatic memory release improving your overall vitality and strength.
My approach is thorough. I’m not just identifying blockages in your flow of Qi or areas of energetic stagnancy; 
I actively employ one of my favorite tools that I like to call my "big hammers" to initiate meaningful change. 
This might sound intense, but it's a journey we undertake with care and precision. I blend clear, actionable guidance 
tailored to your individual needs with a compassionate touch with each session.
Beyond my passion for working with clients, and making positive changes in people’s lives, 
 my life is rich with adoring wife, kids, and pets, I have a love for sports, nature, continuing education, 
 and keeping a morning ritual for my daily personal practices.
So, whether you are feeling stuck or have a sense that things aren't flowing as they should, our collaboration will be a 
journey to not only uncover and release those hidden areas but also to enrich your understanding of how your energy works. 
Together, let’s chart a course towards your success.
</p>
<p class="text-start">
<a class="link-color" href="#appointments">Scroll down to Make an Appointment With Dale</a>
The “Notes” section will provide you with the opportunity to tell me about any injuries, target areas, preferences, or other information. You may also specify the service requested, though it is not required.
</p>

<ul class="list-group-item text-start" >25 - Minute Free consultation</ul>
<ul class="list-group-item text-start" >1 hour sessions are $75</ul>
<ul class="list-group-item text-start" >90 min sessions are $110</ul>
<!-- <ul class="list-group-item text-start" >120 min sessions are $145</ul> -->
<h5 class="text-start fw-bold">Elevate your Essence:</h5> <p class="text-start">The Complete Energy Overhaul Special Package Deal: $260  Embark on a transformative journey with this trio of sessions.. 
This extended engagement allows for deeper work and more profound changes, enhancing your capacity for physical vitality, mental clarity, 
emotional well-being, and inner strength. With a focus on long-term benefits, these sessions build upon each other to clear blockages, 
enhance energy flow, and promote a flourishing state of health. Your first session is scheduled through this calendar process below and the other two 
will be determined on the maintenance plan discussed tailored to your goals. Once your first session begins, the remaining sessions cannot be gifted to someone else.
Once scheduled, please indicate in the notes section you have are wanting the Elevate your Essense Package</p>
<h6 class="text-start fw-bold">Package includes:</h6>
<ul class="text-start">(1) 2-hour session: Begin your transformation with a comprehensive 2-hour session designed to assess and address your energy needs. 
  This session focuses on initial consultation, building a rapport,  uncovering and initiating the healing process, and setting a solid foundation for your goals.</ul>
<ul class="text-start">(2) 1-hour sessions: Continue your path to revitalization with two 1-hour sessions scheduled separately that build upon the initial work. 
  These sessions are tailored to target specific areas of concern, facilitating deeper healing and ensuring a more dynamic energy flow throughout your body.</ul>
<ul class="text-start">Follow-ups, and guided external healing methods combined with Neigong and Reiki specialty taught to continue your success.

</ul>
<div class="row text-start">Payment can be collected on the day of your session, you can send by one of these options or energy exchange with cash when we meet. If you need to cancel or reschedule your appointment, please contact me, or find your original confirmation scheduling email to use my user-friendly online portal.
<div class="row"><br></div>
<div class="row">
<ul>Cash:  Cash is KING!</ul>
<ul>Venmo: <a class="link-color" href="https://venmo.com/u/Dale-Hartung"><svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 24 24">
	<path fill="currentColor" d="M14 3.27a7.49 7.49 0 0 1 .66 3.35c0 2.72-1.93 6.72-3.49 9.27L9.53 2.44l-6.91.65L5.79 22h7.88c3.45-4.54 7.71-11 7.71-16a7.3 7.3 0 0 0-1.06-4z" />
</svg></a></ul></div>
</div>
 <br><br>
 <!-- <div class="row"><div class="col"></div><div class="col"></div><div class="col"></div></div> -->
<!-- <div class="row text-center">Connect with me on Social media:</div> -->
 <div class="row">
 <div class="col"><a href="https://www.facebook.com/people/Energy-Paths/100093296416895/">

 <i class="fab fa-facebook-f fa-2x" style="color: #3b5998;"></i><svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" fill="currentColor" class="bi bi-facebook" viewBox="0 0 16 16">
  <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951"/>
</svg></a></div>
<div class="col"><a href="https://www.instagram.com/energypathsllc/?hl=en"><svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" fill="currentColor" class="bi bi-instagram" viewBox="0 0 16 16">
  <path d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.9 3.9 0 0 0-1.417.923A3.9 3.9 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.9 3.9 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.9 3.9 0 0 0-.923-1.417A3.9 3.9 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599s.453.546.598.92c.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.5 2.5 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.5 2.5 0 0 1-.92-.598 2.5 2.5 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233s.008-2.388.046-3.231c.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92s.546-.453.92-.598c.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92m-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217m0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334"/>
</svg></a></div>
<div class="col"><a href="https://www.tiktok.com/@energypaths"><svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" fill="currentColor" class="bi bi-tiktok" viewBox="0 0 16 16">
  <path d="M9 0h1.98c.144.715.54 1.617 1.235 2.512C12.895 3.389 13.797 4 15 4v2c-1.753 0-3.07-.814-4-1.829V11a5 5 0 1 1-5-5v2a3 3 0 1 0 3 3z"/>
</svg></a>
<br><br>
</div>
<div class="row">
<!-- <div class="col"></div> -->
<div class="col"><p class="text-start fw-bold">If you cant find a time that works for you on the booking site send me a text and we'll find a time and location that works for you!</p>
  <p class="text-center fw-bold">262-370-4314</p>
</div>
<!-- <div class="col"></div> -->
</div></div>
         
    </div>
    
      <div id="appointments" class="embed-resposive-item">
        <br>
        <iframe src="https://energypathsllc.youcanbook.me/?noframe=true&skipHeaderFooter=true" id="ycbmiframeenergypathsllc" style="width:100%;height:1000px;border:0px;background-color:transparent;" frameborder="0" allowtransparency="true"></iframe>
        <!-- <script>window.addEventListener && window.addEventListener("message", function(event){if (event.origin === "https://energypathsllc.youcanbook.me"){document.getElementById("ycbmiframeenergypathsllc").style.height = event.data + "px";}}, false);</script> -->
    </div>
  </div>

</template>

<script>
// @ is an alias to /src
import HelloWorld from '@/components/epMainPage.vue'

export default {
  name: 'HomeView',
  components: {
    HelloWorld
  },
  data() {
    return {
      divWidth: '100%',
      frameWidth: '1000%',
      frameHeight: '100%'
    }
  },
  // created: {
  //   fh() {
  //     return this.$refs.iframe.offsetWidth
  //   }
  // },
  computed: {
    aspectRatioHeight() {
      // parseInt(this.divWidth, 10)
      return parseInt(this.frameWidth,10) * (9/16) // 16:9 aspect ratio
      // return this.$refs.iframe.offsetWidth * (9/16)
    },
    // frameWidthCalc() {
    //   return this.$refs.iframe.offsetWidth
    // }
  },
  // mounted() {
  //   console.log(this.$refs.iframe.offsetWidth) // logs the current width of the iframe
  // }
}
</script>
<style>
.no-underline {
  text-decoration: none !important
}
.link-color {
  color: #6c757d;
  text-decoration: none;
}

</style>
